/* styles/TeleprompterPage.css */

.teleprompter-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #333;
    color: #fff;
    position: relative;
    overflow: hidden;
    /* Prevent scrollbars outside the container */
}

.teleprompter-toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    margin-bottom: 20px;
    position: sticky;
    top: 0;
    background-color: rgba(51, 51, 51, 0.8);
    z-index: 1000;
    padding: 10px;
    border-radius: 5px;
}

button {
    padding: 10px 20px;
    background-color: #007acc;
    color: #fff;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    margin-right: 10px;
    transition: background-color 0.3s ease;
}

button:active {
    background-color: #004492;
}

button:hover {
    background-color: #0056b3;
}

label {
    display: flex;
    align-items: center;
    font-size: 18px;
}

input[type="number"],
input[type="checkbox"] {
    margin-left: 10px;
    padding: 5px;
    font-size: 16px;
    background-color: #444;
    color: #fff;
    border: none;
    border-radius: 5px;
}

.teleprompter-text {
    margin-top: 20px;
    font-size: 18px;
    text-align: left;
    max-width: 80%;
    overflow: hidden;
    min-height: 50vh;
    transform: scaleY(1);
    overflow-y: auto;
    /* Enable vertical scrolling */
    padding-right: 15px;
    /* Add some right padding for scrollbar space */

}

.teleprompter-text pre {
    text-wrap: wrap;    
}


.teleprompter-text.mirrored {
    transform: scaleX(-1);
}

.teleprompter-timer {
    color: #ccc;
    font-size: 16px;
    margin-left: 10px;
}

/* styles/TeleprompterPage.css */

/* ... (existing CSS) ... */

.teleprompter-text {
    /* ... (existing styles) ... */
    scrollbar-width: thin;
    /* Add a scrollbar on supported browsers */
    scrollbar-color: transparent transparent;
    /* Hide scrollbar handle */
}

.teleprompter-text::-webkit-scrollbar {
    width: 6px;
    /* Width of the scrollbar */
}

.teleprompter-text::-webkit-scrollbar-thumb {
    background-color: transparent;
    /* Hide scrollbar handle in WebKit browsers */
}

.arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 120px;
    height: 120px;
    background-color: transparent;
    z-index: 10;
    /* Make sure arrows are above the scrolling text */
}

.left-arrow {
    left: 0;
}

.right-arrow {
    right: 0;
}

.bottom-padding {
    height: 50vh;
    /* This will add space equivalent to half the viewport height at the bottom */
}

.top-padding {
    height: 50vh;
    /* This will add space equivalent to half the viewport height at the bottom */
}

.highlight-rectangle {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);    
    left: 9%;
    /* Adjust as needed */
    right: 9%;
    /* Adjust as needed */
    height: 12%;
    min-height: 120px;
    /* Match the height of the arrows */
    background-color: rgba(255, 255, 255, 0.2);
    /* Translucent white for a subtle highlight */
    z-index: 5;
    /* Make sure it's below the arrows but above the text */
    border-radius: 5px;
    /* Optional: to give it slightly rounded corners */
}

.fa-icon {
    font-size: 18px;
    /* Adjust size */
    color: #fff;
    /* Adjust color */
    margin-right: 5px;
    /* Space if you want to combine with text */
}

label {
    /* ... existing styles ... */
    align-items: center;
    /* To ensure vertical centering */
}

label .fa-icon {
    margin-right: 8px;
    /* Space between the icon and the input field */
}


.exit-button {
    background-color: #f44336;
    /* red color for emphasis */
    display: flex;
    align-items: center;
}

.exit-button .fa-times {
    margin-right: 8px;
    /* spacing between the icon and the text */
}

.exit-button:hover {
    background-color: #d32f2f;
    /* slightly darker red for hover */
}

.exit-button:active {
    background-color: #b71c1c;
    /* even darker red for active state */
}

/* ... (other existing CSS) ... */

/* Media query for tablets and smaller screens */
@media (max-width: 768px) {
    .teleprompter-toolbar {
        flex-direction: column;
        /* Stack items vertically */
        align-items: stretch;
        /* Stretch items to full width */
        width: 100%;
        /* Take full width */
        padding: 5px;
    }

    button {
        margin-bottom: 10px;
        /* Add space below each button */
    }

    .teleprompter-text {
        max-width: 95%;
        /* Increase width */
        font-size: 16px;
        /* Adjust font size for smaller screens */
    }

    .arrow {
        width: 80px;
        /* Reduce arrow size */
        height: 80px;
    }
}

/* Media query for mobile devices */
@media (max-width: 480px) {
    .teleprompter-toolbar {
        padding: 3px;
    }

    label {
        font-size: 14px;
        /* Adjust font size */
    }

    input[type="number"],
    input[type="checkbox"] {
        font-size: 14px;
        /* Adjust input font size */
    }

    .teleprompter-text {
        font-size: 14px;
        /* Adjust text size for mobile screens */
    }

    .teleprompter-timer {
        font-size: 14px;
        /* Adjust timer font size */
    }

    .arrow {
        display: none;
        /* Hide arrows on very small screens */
    }
}