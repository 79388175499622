/* styles/MainScreen.css */
body {
  background-color: #333;
}

.main-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 100px;
  background-color: #333;
  color: #fff;

  padding: 20px;
  box-sizing: border-box;
}

textarea {
  width: 80%;
  padding: 10px;
  font-size: 16px;
  background-color: #444;
  border: none;
  color: #fff;
  resize: none;
  outline: none;
  border-radius: 5px;
}

button {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #007acc;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

button:hover {
  background-color: #0056b3;
}

/* styles/MainScreen.css (or wherever you're storing your styles) */

/* ... existing styles ... */

.language-dropdown {

  /* Existing styles for the combobox */
  padding: 5px;
  font-size: 16px;
  background-color: #444;
  color: #fff;
  border: none;
  border-radius: 5px;
  appearance: none;
  /* Remove platform-specific styling */
  cursor: pointer;

  /* Add a custom dropdown arrow (optional) */
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'><path d='M4 6l4 4 4-4z' fill='%23fff'/></svg>");
  background-repeat: no-repeat;
  background-position: right 5px center;
  padding-right: 25px;
  /* Space for the dropdown arrow */
}

.language-dropdown:hover {
  background-color: #555;
}

.language-dropdown:focus {
  outline: none;
  /* Remove default focus outline */
  box-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
  /* Add a subtle focus glow */
}

.language-dropdown:active {
  background-color: #333;
}

/* Add an arrow-down icon for visual indication */
.language-dropdown:after {
  content: '\25BC';
  /* Unicode down arrow */
  font-size: 10px;
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}

/* If you use a container/wrapper div around your select */
.dropdown-wrapper {
  position: absolute;
  top: 10px;
  /* Adjust based on your desired margin from the top */
  right: 10px;
  /* Adjust based on your desired margin from the right */
  z-index: 100;
  /* Ensuring the combobox stays on top */

  display: inline-block;
  width: 200px;
  /* Adjust as needed */
}

/* styles/MainScreen.css */

.main-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #333;
  /* Adjust to your theme color */
  padding: 50px 0 0 0;
  width: 100%;
  color: #fff;
  font-size: 12px;
}

/* styles/MainScreen.css */

.logo-container {
  position: absolute;
  top: 10px;
  /* Adjust based on your desired margin from the top */
  left: 10px;
  /* Adjust based on your desired margin from the left */
  z-index: 100;
  /* Ensuring the logo stays on top */
}

.logo {
  max-width: 150px;
  /* Adjust based on your preference */
  height: auto;
}

/* Existing styles... */

h2 {
  display: flex;
  align-items: center;
  font-size: 24px;
  margin-bottom: 12px;
}

h2>svg {
  margin-right: 10px;
  font-size: 16px;
  /* Adjust this size as required */
}



ol li {
  margin-bottom: 8px;
}

.instructions-columns {
  display: flex;
  justify-content: space-between;
  max-width: 650px;
}

.column {
  flex: 1;
  /* Makes each column take up equal width */
  font-size: 14px;
  list-style-type: none;
  /* Removes default bullet points */
  margin-right: 20px;
  /* Space between columns */
}

.column:last-child {
  margin-right: 0;
  /* Ensures the last column doesn't have extra margin on its right side */
}

.column li {
  margin-bottom: 8px;
}

.main-title {
  font-size: 40px;
  color: #fff;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-title .faMicrophone {
  margin-right: 10px;
}

textarea,
.ace_editor {
  width: 100%;
  /* ensures the textarea or editor scales with its container */
  resize: vertical;
  /* allows vertical resizing only */
}

.main-screen button {
  margin-right: 10px;
  /* Add spacing between the buttons */
}

.button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  /* space between the textarea/editor and the buttons */
}

/* If you want to make the buttons a bit bigger on mobile for better touch targets: */
@media (max-width: 768px) {
  .main-screen {
    max-width: 700px;
    /* or whatever maximum width you want */
    margin: 0 auto;
    /* centers the content on larger screens */
  }

  .button-group button {
    padding: 12px 24px;
    font-size: 16px;
  }

  .main-title {
    font-size: 26px;
    padding-top: 35px;

  }

  .logo {
    max-width: 100px;
  }
}